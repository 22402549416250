import styled from "styled-components";

const A = styled.a.attrs({
    href: props => props.href || null,
    target: "blank_"
})`
    text-decoration: none;
    color: #fff;
    border-bottom: 1px solid rgba(255, 255, 255, .7);
    transition: .5s all ease;


    &:hover {
        border-bottom: 1px solid rgba(255, 255, 255, 1);
    }
`;

export default A;
