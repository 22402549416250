import React, { Component } from 'react';
import Anime from 'react-anime';
import styled from 'styled-components';
import H1 from './components/H1';
import P from './components/P';
import A from './components/A';

import './index.css';

const Main = styled.main`
	display: flex;
	padding: 50px;
	align-items: center;
	flex: 1;
`;

const Li = styled.li`
	display: 'block';
	margin: 5px 0;
`;

class App extends Component {
	state = {
		play: false
	};
	componentDidMount() {
		setTimeout(() => {
			this.setState({
				play: true
			});
		}, 1000);
	}
	render() {
		const { play } = this.state;

		return (
			<Main>
				<section style={{ display: 'block' }}>
					<H1>
						Billy Rukh
						<span role="image" aria-label="robot">
							{' '}
							🤖{' '}
						</span>
					</H1>
					<P fs="22px" style={{ paddingBottom: '10px' }}>
						Product Engineer
					</P>

					<div style={{ paddingTop: '3em', paddingBottom: '1rem' }}>
						<Anime
							easing="easeOutElastic"
							loop={true}
							autoplay={play}
							duration={2500}
							direction="alternate"
							delay={(el, index) => index * 1500}
							translateX="8rem"
							rotate={[0, 360]}
						>
							<div className="circle" />
							<div className="square" />
							<div className="triangle" />
						</Anime>
					</div>
					<P style={{ paddingBottom: '3rem' }}>
						I design and develop digital products for the web and
						mobile. I am currently working on{' '}
						<A href="http://meetserv.com">Serv</A>, which simplifies
						ordering and payments at restaurants. Previously I led
						teams at{' '}
						<A href="https://gohyperlabs.com">Hyper Labs</A>, a
						digital product development agency based out of
						beautiful Houston, Texas. Learn more about me by
						checking out my work or sending me a message below.
					</P>
					<H1 fs="16px">
						On the web
						<span role="image" label="earth">
							{' '}
							🌎
						</span>
					</H1>
					<ul>
						<Li>
							<A
								href="https://twitter.com/metr0n_"
								color="#55acee"
							>
								Twitter
							</A>
						</Li>
						<Li>
							<A href="https://github.com/brukh">Github</A>
						</Li>
						<Li>
							<A href="https://angel.co/brukh">AngelList</A>
						</Li>
						<Li>
							<A href="https://medium.com/@billyrukh">Medium</A>
						</Li>
						<Li>
							<A href="mailto:billy@gohyperlabs.com">Mail</A>
						</Li>
					</ul>
				</section>
			</Main>
		);
	}
}

export default App;
