import styled from "styled-components";

const P = styled.p`
    font-weight: 300;
    color: #fff;
    max-width: 700px;
    ${props => (props.fs ? props.fs : "18px")};
`;

export default P;
